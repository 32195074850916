// 跳转平台个人信息
export const jumpPlatformFun = () => {
  window.open(
    `${process.env.VUE_APP_JUMPURL}?userToken=${localStorage.getItem('token')}`
  )
}

// 判断登录端
export const checkBrowser = () => {
  var ua = navigator.userAgent.toLowerCase()
  if (
    ua.match(/MicroMessenger/i) == 'micromessenger' ||
    ua.indexOf('wechat') > -1
  ) {
    // 微信浏览器
    return 'isWx'
  }
}

// 跳转平台
export const JumpPlatform = () => {
  if (checkBrowser() == 'isWx' || window.innerWidth < 900) {
    window.location.href = process.env.VUE_APP_WECHARTJUMP
  } else {
    localStorage.getItem('token')
      ? window.open(
        `${process.env.VUE_APP_JUMPURL}?userToken=${localStorage.getItem(
          'token'
        )}`
      )
      : window.open(process.env.VUE_APP_JUMPURL)
  }
}

export const getRequest = (value) => {
  // url例子：www.baidu.com?id="123456"&name="www"；
  var url = decodeURI(window.location.search)
  var object = {}
  if (url.indexOf('?') !== -1) {
    // url中存在问号，也就说有参数。
    var str = url.substr(1) // 得到?后面的字符串
    var strs = str.split('&') // 将得到的参数分隔成数组[id="123456",name="www"];
    for (var i = 0; i < strs.length; i++) {
      object[strs[i].split('=')[0]] = strs[i].split('=')[1] // 得到{id:'123456',name:'www'}
    }
  }
  if (!url || url === '') {
    url = document.referrer
    if (/baidu\.com/.test(url)) {
      return 2
    }
  }
  return object[value]
}
